import Alpine from "alpinejs";

import { ua } from "../vendor/ua.js";
import { ru } from "../vendor/ru.js";
export const currentLanguage = {
    ua,
    ru,
    lang: [],
    id: 1,

    init() {
        this.ru = ru;
        this.ua = ua
        setTimeout(e => {
            this.lang = this.ua;
        }, 10)
    },

    changeLang(str) {
        if (str === 'ua') {
            this.lang = this.ua;
            this.id = 1
        } else if (str === 'ru') {
            this.lang = this.ru
            this.id = 2
        }
    }
}