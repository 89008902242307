import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import {mobileMenu} from './store/mobile-menu';
import {stopScroll} from './store/stop-scroll';
import {content} from "./components/content";
import {currentLanguage} from './store/currentLanguage';
import {sliderSwiper} from "./components/slider-swiper";


document.addEventListener('alpine:init', () => {
    Alpine.plugin(collapse);

    Alpine.store("mobileMenu", mobileMenu);
    Alpine.store("stopScroll", stopScroll);
    Alpine.store("currentLanguage", currentLanguage);

    Alpine.data("content", content);
    Alpine.data("sliderSwiper", sliderSwiper);
});


window.Alpine = Alpine

Alpine.start();


const headerElement = document.querySelector('header');
const headerHeight = headerElement.offsetHeight;

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const target = document.querySelector(this.getAttribute('href'));
        var anchor1 = this.getAttribute('href'); // Получить значение атрибута href

        // Обновить URL с якорем
        window.location.hash = anchor1;
        if (target) {
            const targetPosition = target.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({
                top: targetPosition - headerHeight - 150,
                behavior: 'smooth'
            });
        }
    });
});

// form submit

document.getElementById('myForm').addEventListener('submit', function(e) {
    e.preventDefault();
    let name = document.getElementById('name').value;
    let phone = document.getElementById('phone').value;
    let message = `Name: ${name}\nPhone: ${phone}`;

    let token = '7470298877:AAGghhed5LSYJ9Ma1tIWAgJhIYBK8HT71Rw';
    let chat_id = '-1002148713968';
    let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${encodeURIComponent(message)}`;

    fetch(url, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data => {
            if (data.ok) {
                alert('Ми з вами зв\'яжемось');
                document.getElementById('myForm').reset();
            } else {
                alert('Помилка відправки повідомлення.');
            }
        })
        .catch(error => console.error('Error:', error));
});