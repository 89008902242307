export const ru = {
    header: {
        price: 'Цены',
        blog: 'О нас',
        reviews: 'Статья',
        gallery: 'Отзывы',
        work: 'Наши роботи',
        contacts: 'Контакты',
    },
    hero: {
        title: 'Сервис',
        text: 'Утепление квартир многоэтажек и частных домов под ключ с гарантией до 10 лет',
        list: {
            text1: '- Опыт уже 15 лет',
            text2: '- Работаем по договору',
            text3: '- Качественные материалы',
        },
        btnText: 'Бесплатный просчет стоимости',
        free: 'Менеджер от компании ПромАльпСервис выезжает на замер объекта БЕСПЛАТНО!',
    },
    works: {
        title: 'Наши работы',
        btnOpen: 'Показать больше',
        btnClose: 'Показать меньше',
    },
    insulate: {
        title: 'Почему стоит утеплить стену или фасад?',
        block1: {
            title: 'ТЕПЛО ЗИМОЙ',
            text: 'Благодаря утеплению в помещении содержится более 30% тепла',
        },
        block2: {
            title: 'Прохладно летом',
            text: 'Утеплитель не позволяет помещению сильно нагревается.',
        },
        block3: {
            title: 'ЭКОНОМИЯ ОТОПЛЕНИЯ',
            text: 'Утепляя стены квартир или фасады домов, Вы экономите на отоплении до 40%',
        },
        block4: {
            title: 'ДОСТУПНАЯ СТОИМОСТЬ',
            text: 'Цены на утепление стен и фасадов доступны для большинства киевлян.',
        },
        title2: 'Команда, которая будет работать над утеплением в вашем доме',
        job: 'Верхолаз-монтажник',
        team1: {
            name: 'Дивный Александр Юрьевич',
            stage: 'Опыт работы 8 лет',
        },
        team2: {
            name: 'Шлома Сергей Николаевич',
            stage: 'Опыт работы 7 лет',
        },
        team3: {
            name: 'Рогизный Василь Олегович',
            stage: 'Опыт работы 4 лет',
        },
        team4: {
            name: 'Кулинич Евгений Викторович',
            stage: 'Опыт работы 7 лет',
        },
        team5: {
            name: 'Кулаковский Дмитрий Анатольевич',
            stage: 'Опыт работы 9 лет',
        },
        team6: {
            name: 'Кондратьев Артем Николаевич ',
            stage: 'Опыт работы 5 лет',
        },
    },
    graphite: {
        title: 'Процесс утепления: шаг за шагом',
        title2: 'к уюту и экономии энергии',
        block1: {
            title: 'Выбор материалов:',
            text: 'С учетом вашего бюджета и требований мы совместно выбираем оптимальные материалы для утепления.',
        },
        block2: {
            title: 'Подготовка поверхности и монтаж:',
            text: 'Перед началом утепления мы тщательно подготавливаем поверхность. Затем наша квалифицированная команда производит монтаж утепляющих материалов, обеспечивая их правильное положение и герметичность.',
        },
        block3: {
            title: 'Завершающие работы и контроль качества:',
            text: 'Мы заботимся о каждом пустяке, завершая утепление эстетическим оформлением и учитывая все детали. После завершения мы проводим контроль качества, убеждаясь, что все требования выполнены на высшем уровне.',
        },
        block4: {
            title: 'Наслаждайтесь комфортом и экономией энергии:',
            text: 'После завершения процесса утепления вы будете наслаждаться уютом вашего нового, энергоэффективного дома. Уменьшение затрат на отопление и повышение комфорта – вот то, что вам гарантировано!',
        },
    },
    company: {
        title: 'О компании ПромАльп Сервис',
        text1: '15+ лет опыта на рынке',
        text2: '500 квартир утеплено',
        text3: '500 домов утеплено',
        text4: '24 квалифицированных специалиста',
        text5: '80% клиентов заказывают полный пакет услуг',
        text6: 'более 1000 довольных клиентов',
    },
    howDoWeWork: {
        title: 'Как мы работаем?',
        text1: 'ЗАМЕР ОБЪЕКТА',
        text2: 'Составление сметы',
        text3: 'ЗАКЛЮЧЕНИЕ ДОГОВОРА',
        text4: 'ПЕРЕДПЛАТА НА МАТЕРИАЛ',
        text5: 'ЗАКУПКА МАТЕРИАЛОВ',
        text6: 'ВЫПОЛНЕНИЕ РАБОТ',
        text7: 'ОПЛАТА ПО ФАКТУ ВЫПОЛНЕНИЯ',
    },
    price: {
        title: 'Цены на утепление в Киеве',
        subtitle: 'Система скидок при совместном утеплении жилья',
        text: 'Очень выгодно утепляться вместе с соседями, поскольку стоимость квадратного метра снижается для каждого участника. От компании "ПромАльпСервис" скидка -10% для пенсионеров г.Киева',
        btnTitle: 'Прайс-лист',
        btnText: 'Выбрать материал',
        dropdown: {
            text1: 'Пенопласт 50мм(15кг.м3)',
            text2: 'Пенопласт 100мм(10кг.м3)',
            text3: 'Пенопласт 100мм(15кг.м3)',
            text4: 'Графитовый пенопласт 50мм(15кг.м3)',
            text5: 'Графитовый пенопласт 100мм(15кг.м3)',
        },
    },
    form: {
        title: 'Заказать бесплатный замер',
        subtitle: 'Заполните форму, чтобы к вам выехал мастер для бесплатного замера и просчета стоимости.',
        name: 'Ваше имя',
        phone: 'Номер телефона',
        btn: 'Отправить',
    },
    reviews: {
        title: 'Отзывы',
    },
    footer: {
        text: 'Мы в социальных сетях:',
        text2: 'Контакты:',
    },
}