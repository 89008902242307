export const ua = {
        header: {
            price: 'Ціни',
            blog: 'Про нас',
            reviews: 'Стаття',
            gallery: 'Відгуки',
            work: 'Наші роботи',
            contacts: 'Контакти',
        },
        hero: {
            title: 'Сервіс',
            text: 'Утеплення квартир багатоповерхівок та приватних будинків під ключ із гарантією до 10 років',
            list: {
                text1: '- Досвід вже 15 років',
                text2: '- Працюємо по договору',
                text3: '- Якісні матеріали',
            },
            btnText: 'Безкоштовний прорахунок вартості',
            free: 'Менеджер від компанії ПромАльпСервіс виїжджає на замір об\'єкту БЕЗКОШТОВНО!',
        },
        works: {
            title: 'Наші роботи',
            btnOpen: 'Показати більше',
            btnClose: 'Показати меньше',
        },
        insulate: {
            title: 'Чому варто утеплити стіну чи фасад?',
            block1: {
                title: 'ТЕПЛО ВЗИМКУ',
                text: 'Завдяки утепленню у приміщенні утримується понад 30% тепла',
            },
            block2: {
                title: 'ПРОХОЛОДНО ВЛІТКУ',
                text: 'Утеплювач не дозволяє приміщенню сильно нагрівається',
            },
            block3: {
                title: 'ЕКОНОМІЯ ОПАЛЕННЯ',
                text: 'Утеплюючи стіни квартир або фасади будинків, Ви заощаджуєте на опаленні до 40%',
            },
            block4: {
                title: 'ДОСТУПНА ВАРТІСТЬ',
                text: 'Ціни на утеплення стін та фасадів доступні для більшості киян',
            },
            title2: 'Команда, яка буде працювати над утепленням у вашій домівці',
            job: 'Верхолаз-монтажник',
            team1: {
                name: 'Дивний Олександр Юрійович',
                stage: 'Досвід роботи 8 років',
            },
            team2: {
                name: 'Шлома Сергій Миколайович',
                stage: 'Досвід роботи 7 років',
            },
            team3: {
                name: 'Рогізний Василь Олегович',
                stage: 'Опыт работы 4 лет',
            },
            team4: {
                name: 'Кулініч Євген Вікторович',
                stage: 'Досвід роботи 7 років',
            },
            team5: {
                name: 'Кулаковський Дмитро Анатолійович',
                stage: 'Досвід роботи 9 років',
            },
            team6: {
                name: 'Кондратьєв Артем Миколайович',
                stage: 'Досвід роботи 5 років',
            },
        },
        graphite: {
            title: 'Процес утеплення: крок за кроком',
            title2: 'до затишку та економії енергії',
            block1: {
                title: 'Вибір матеріалів:',
                text: 'З урахуванням вашого бюджету та вимог, ми спільно вибираємо оптимальні матеріали для утеплення.',
            },
            block2: {
                title: 'Підготовка поверхні та монтаж:',
                text: 'Перед початком утеплення ми ретельно підготовлюємо поверхню. Потім наша кваліфікована команда проводить монтаж утеплюючих матеріалів, забезпечуючи їх правильне положення та герметичність.',
            },
            block3: {
                title: 'Завершальні роботи та контроль якості:',
                text: 'Ми дбаємо про кожний дрібницю, завершуючи утеплення естетичним оформленням та враховуючи всі деталі. Після завершення ми проводимо контроль якості, переконуючись в тому, що усі вимоги виконані на вищому рівні.',
            },
            block4: {
                title: 'Насолоджуйтесь комфортом та економією енергії:',
                text: 'Після завершення процесу утеплення ви будете насолоджуватися затишком вашого нового, енергоефективного будинку. Зменшення витрат на опалення та підвищення комфорту - ось те, що вам гарантовано!',
            },
        },
        company: {
            title: 'Про компанiю ПромАльп Сервіс',
            text1: '15+ рокiв досвiду на ринку',
            text2: '500 квартир утеплено',
            text3: '500 будинкiв утеплено',
            text4: '24 кваліфікованих фахівця',
            text5: '80% клієнтів замовляють повний пакет послуг',
            text6: 'більше 1000 довольних клієнтів',
        },
        howDoWeWork: {
            title: 'Як ми працюємо?',
            text1: 'ЗАМІР ОБ\'ЄКТА',
            text2: 'СКЛАДАННЯ КОШТОРИСУ',
            text3: 'УКЛАДАННЯ ДОГОВОРУ',
            text4: 'ПЕРЕДПЛАТА НА МАТЕРІАЛ',
            text5: 'ЗАКУПІВЛЯ МАТЕРІАЛІВ',
            text6: 'ВИКОНАННЯ РОБІТ',
            text7: 'ОПЛАТА ЗА ФАКТОМ ВИКОНАННЯ',
        },
        price: {
            title: 'Ціни на утеплення у Києві',
            subtitle: 'Система знижок при сумісному утепленні житла',
            text: 'Дуже вигідно утеплюватися разом із сусідами, оскільки вартість квадратного метра знижується кожному за учасника. Від компанії "ПромАльпСервіс" знижка -10% для пенсіонерів м.Києва',
            btnTitle: 'Прайс-ліст',
            btnText: 'Обрати матеріал',
            dropdown: {
                text1: 'Пінопласт 50мм(15кг.м3)',
                text2: 'Пінопласт 100мм(10кг.м3)',
                text3: 'Пінопласт 100мм(15кг.м3)',
                text4: 'Графітовий пінопласт 50мм(15кг.м3)',
                text5: 'Графітовий пінопласт 100мм(15кг.м3)',
            },
        },
        form: {
            title: 'Замовити безкоштовний замір',
            subtitle: 'Заповніть форму, щоб до вас виїхав майстер для безкоштовного заміру і прорахунку вартості.',
            name: 'Ваше ім`я',
            phone: 'Номер телефону',
            btn: 'Надіслати',
        },
        reviews: {
            title: 'Відгуки',
            block1: {
                name: 'Юлія',
                text: 'Звернулися за утепленням невеличкої ділянки стіни. Консультант зрозуміло пояснив хід виконання робіт. Вартість адекватна. Майстер працював швидко, якісно, охайно. Ми залишилися задоволені роботою, дякую. РЕКОМЕНДУЮ !!!',
            },
        },
        footer: {
            text: 'Ми в соціальних мережах:',
            text2: 'Контакти:',
        },
    }



