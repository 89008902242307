import Swiper, {EffectCards, Navigation, Pagination} from 'swiper';

Swiper.use([Pagination, Navigation, EffectCards]);

const sliderOptions = {
    team: {
        // spaceBetween: 20,
        loop: false,
        slidesPerView: 1,
        // autoHeight: true,
        effect: "cards",
        grabCursor: true,
        centeredSlides: true,
    },
}

export const sliderSwiper = (sliderOption) => ({
    slider: null,

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initSwiper();
        });
    },

    initSwiper() {
        this.slider = new Swiper(this.$el, sliderOptions[sliderOption]);
    },
});