export const reviews = [
    {
        id: 1,
        img: "./img/mainPage/reviews/1.png",
    },
    {
        id: 2,
        img: "./img/mainPage/reviews/2.png",
    },
    {
        id: 3,
        img: "./img/mainPage/reviews/3.png",
    },
    {
        id: 4,
        img: "./img/mainPage/reviews/4.png",
    },
    {
        id: 5,
        img: "./img/mainPage/reviews/5.png",
    },
    {
        id: 6,
        img: "./img/mainPage/reviews/6.png",
    },
];

