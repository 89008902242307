import Swiper, { Pagination, Navigation, Autoplay } from "swiper";
Swiper.use([Pagination, Navigation, Autoplay]);

import { our_works } from "../vendor/our-works.js";
import { reviews } from "../vendor/reviews.js";
import Alpine from "alpinejs";

export const content = () => ({
    our_works,
    reviews,
    sliderModal: null,
    idSlide: null,
    openModal: false,

    init() {
        document.addEventListener("DOMContentLoaded", () => {
            this.initSwiper();
            this.our_works = our_works;
            this.reviews = reviews;
        });
    },

    show() {
        this.openModal = true;
        // Alpine.store("stopScroll").enable();
    },

    hide() {
        this.openModal = false;
        // Alpine.store("stopScroll").disable();
    },

    initSwiper() {
        this.sliderModal = new Swiper(this.$refs["swiper-modal"], {
            slidesPerView: 1,
            initialSlide: this.idSlide,
            spaceBetween: 15,
            // allowTouchMove: false,
            speed: 1000,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    },
});
