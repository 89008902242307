export const our_works = [
    {
        id: 1,
        img: "./img/mainPage/our-works/1.jpg",
    },
    {
        id: 2,
        img: "./img/mainPage/our-works/2.jpg",
    },
    {
        id: 3,
        img: "./img/mainPage/our-works/3.jpg",
    },
    {
        id: 4,
        img: "./img/mainPage/our-works/4.jpg",
    },
    {
        id: 5,
        img: "./img/mainPage/our-works/5.jpg",
    },
    {
        id: 6,
        img: "./img/mainPage/our-works/6.jpg",
    },
    {
        id: 7,
        img: "./img/mainPage/our-works/7.jpg",
    },
    {
        id: 8,
        img: "./img/mainPage/our-works/8.jpg",
    },
    {
        id: 9,
        img: "./img/mainPage/our-works/9.jpg",
    },
    {
        id: 10,
        img: "./img/mainPage/our-works/10.jpg",
    },
    {
        id: 11,
        img: "./img/mainPage/our-works/11.jpg",
    },
    {
        id: 12,
        img: "./img/mainPage/our-works/12.jpg",
    },
    {
        id: 13,
        img: "./img/mainPage/our-works/13.jpg",
    },
    {
        id: 14,
        img: "./img/mainPage/our-works/14.jpg",
    },
    {
        id: 15,
        img: "./img/mainPage/our-works/15.jpg",
    },
    {
        id: 16,
        img: "./img/mainPage/our-works/16.jpg",
    },
    {
        id: 17,
        img: "./img/mainPage/our-works/17.jpg",
    },
    {
        id: 18,
        img: "./img/mainPage/our-works/18.jpg",
    },
    {
        id: 19,
        img: "./img/mainPage/our-works/19.jpg",
    },
    {
        id: 20,
        img: "./img/mainPage/our-works/20.jpg",
    },
    {
        id: 21,
        img: "./img/mainPage/our-works/21.jpg",
    },
    {
        id: 22,
        img: "./img/mainPage/our-works/22.jpg",
    },
    {
        id: 23,
        img: "./img/mainPage/our-works/23.jpg",
    },
    {
        id: 24,
        img: "./img/mainPage/our-works/24.jpg",
    },
    {
        id: 25,
        img: "./img/mainPage/our-works/25.jpg",
    },
    {
        id: 26,
        img: "./img/mainPage/our-works/26.jpg",
    },
    {
        id: 27,
        img: "./img/mainPage/our-works/27.jpg",
    },
    {
        id: 28,
        img: "./img/mainPage/our-works/28.jpg",
    },
    {
        id: 29,
        img: "./img/mainPage/our-works/29.jpg",
    },
    {
        id: 30,
        img: "./img/mainPage/our-works/30.jpg",
    },
    {
        id: 31,
        img: "./img/mainPage/our-works/31.jpg",
    },
    {
        id: 32,
        img: "./img/mainPage/our-works/32.jpg",
    },
    {
        id: 33,
        img: "./img/mainPage/our-works/33.jpg",
    },
    {
        id: 34,
        img: "./img/mainPage/our-works/34.jpg",
    },
    {
        id: 35,
        img: "./img/mainPage/our-works/35.jpg",
    },
    {
        id: 36,
        img: "./img/mainPage/our-works/36.jpg",
    },
    {
        id: 37,
        img: "./img/mainPage/our-works/37.jpg",
    },
    {
        id: 38,
        img: "./img/mainPage/our-works/38.jpg",
    },
    {
        id: 39,
        img: "./img/mainPage/our-works/39.jpg",
    },
    {
        id: 40,
        img: "./img/mainPage/our-works/40.jpg",
    },
    {
        id: 41,
        img: "./img/mainPage/our-works/41.jpg",
    },
    {
        id: 42,
        img: "./img/mainPage/our-works/42.jpg",
    },
    {
        id: 43,
        img: "./img/mainPage/our-works/43.jpg",
    },
    {
        id: 44,
        img: "./img/mainPage/our-works/44.jpg",
    },
    {
        id: 45,
        img: "./img/mainPage/our-works/45.jpg",
    },
    {
        id: 46,
        img: "./img/mainPage/our-works/46.jpg",
    },
    {
        id: 47,
        img: "./img/mainPage/our-works/47.jpg",
    },
    {
        id: 48,
        img: "./img/mainPage/our-works/48.jpg",
    },
    {
        id: 49,
        img: "./img/mainPage/our-works/49.jpg",
    },
    {
        id: 50,
        img: "./img/mainPage/our-works/50.jpg",
    },
];

